import React from 'react'
import { Text, Center, Link as Wrapper } from '@chakra-ui/react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Error404 = () => {
  return (
    <Layout>
      <SEO
        title='404: not found'
        keywords={['composer', 'musician', 'web-developer']}
      />
      <StaticImage
        style={{
          filter: 'invert(100%)',
          transform: 'rotate(180deg)',
        }}
        src='../../images/naama.jpg'
        alt='picture of the author'
      />
      <Text fontSize='4xl'>This page does not exist 👻</Text>
      <Center>
        <Wrapper as={Link} to='/'>
          Back to home
        </Wrapper>
      </Center>
    </Layout>
  )
}

export default Error404
